define("discourse/plugins/GroupInvitation/discourse/templates/connectors/groups-form-membership-below-automatic/allow-invite", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label">{{i18n "group_invitation.invite_user"}}</label>
  
    {{input type="checkbox"
        checked=model.custom_fields.allow_invite_users}}
  
    {{i18n "group_invitation.admin.allow_invite_member"}}
  </div>
  */
  {
    "id": "2qBfHAjv",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"group_invitation.invite_user\"],null]],[13],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"model\",\"custom_fields\",\"allow_invite_users\"]]]]]],[1,\"\\n\\n  \"],[1,[28,[35,0],[\"group_invitation.admin.allow_invite_member\"],null]],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/GroupInvitation/discourse/templates/connectors/groups-form-membership-below-automatic/allow-invite.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"i18n\",\"input\"]]",
    "moduleName": "discourse/plugins/GroupInvitation/discourse/templates/connectors/groups-form-membership-below-automatic/allow-invite.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});