define("discourse/plugins/GroupInvitation/discourse/group-invitation-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("group-invitation", function () {
      this.route("invite", {
        path: "/invite/:groupName"
      });
      this.route("invite-admin", {
        path: "/manage-invitations/:groupName"
      });
    });
  }
  ;
});