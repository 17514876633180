define("discourse/plugins/GroupInvitation/discourse/controllers/invite", ["exports", "@ember/controller", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object", "discourse/plugins/GroupInvitation/discourse/models/invitations"], function (_exports, _controller, _ajax, _ajaxError, _object, _invitations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    inviteeUsername: null,
    actions: {
      updateUsername(selectedUsernames) {
        return this.set("inviteeUsername", (0, _object.get)(selectedUsernames, "firstObject"));
      },
      submit() {
        (0, _ajax.ajax)(`/group-invitation/invite/${this.model.groupName}`, {
          type: "POST",
          data: {
            invitee: this.get("inviteeUsername")
          }
        }).then(data => {
          if (data && data.automatic_admit) {
            this.transitionToRoute("group.members", this.model.groupName);
          } else {
            (0, _invitations.default)(this.model.groupName).then(dataGet => {
              this.replaceRoute("group-invitation.invite", dataGet);
            }).catch(_ajaxError.popupAjaxError);
          }
        }).catch(_ajaxError.popupAjaxError);
      },
      withdrawInvitation(invitation) {
        (0, _ajax.ajax)(`/group-invitation/withdraw-invitation/${this.model.groupName}`, {
          type: "POST",
          data: {
            invitee: invitation.invitee.username,
            inviter: invitation.inviter.username
          }
        }).then(() => {
          (0, _invitations.default)(this.model.groupName).then(data => {
            this.replaceRoute("group-invitation.invite", data);
          }).catch(_ajaxError.popupAjaxError);
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });
});