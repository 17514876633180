define("discourse/plugins/GroupInvitation/discourse/routes/group-invitation-invite", ["exports", "discourse/routes/discourse", "discourse/plugins/GroupInvitation/discourse/models/invitations"], function (_exports, _discourse, _invitations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    controllerName: "invite",
    model(params) {
      return (0, _invitations.default)(params.groupName);
    },
    renderTemplate() {
      this.render("invite");
    }
  });
});