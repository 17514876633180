define("discourse/plugins/GroupInvitation/discourse/models/invitations", ["exports", "discourse/lib/ajax", "discourse/models/user"], function (_exports, _ajax, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  // import RestModel from "discourse/models/rest";

  // export default RestModel.extend({});

  async function asyncForEach(array, callback) {
    let result = [];
    for (let index = 0; index < array.length; index++) {
      result.push(await callback(array[index], index, array));
    }
    return result;
  }
  async function _default(groupName) {
    let filter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
    try {
      let url = `/group-invitation/current-invitations/${groupName}`;
      if (filter === "admin") {
        url += "?filter=admin";
      }
      let data = await (0, _ajax.ajax)(url);
      let invitations = await asyncForEach(data.invitations, async invitation => {
        invitation.inviter = await _user.default.findByUsername(invitation.inviter);
        invitation.invitee = await _user.default.findByUsername(invitation.invitee);
        return invitation;
      });
      return {
        groupName,
        invitations,
        reasonRequired: data.reasonRequired
      };
    } catch (e) {
      rethrow(e);
    }
  }

  // We use this to make sure 404s are caught
  function rethrow(error) {
    if (error.status === 404) {
      throw new Error("404: " + error.responseText);
    }
    throw error;
  }
});