define("discourse/plugins/GroupInvitation/discourse/connectors/group-details-after/invite", ["exports", "discourse/plugins/GroupInvitation/discourse/models/invitations", "discourse/lib/ajax-error"], function (_exports, _invitations, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      invitePage(groupName) {
        (0, _invitations.default)(groupName).then(data => {
          Discourse.__container__.lookup("router:main").transitionTo("group-invitation.invite", data);
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  };
});