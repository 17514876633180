define("discourse/plugins/GroupInvitation/discourse/templates/connectors/group-details-after/invite", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{d-button
      action=(action "invitePage" model.name)
      icon="user-plus"
      class="btn-default"
      label="group_invitation.invite_user"
      disabled=(not model.custom_fields.allow_invite_users)
  }}
  
  */
  {
    "id": "WiZHGF+g",
    "block": "[[[1,[28,[35,0],null,[[\"action\",\"icon\",\"class\",\"label\",\"disabled\"],[[28,[37,1],[[30,0],\"invitePage\",[30,0,[\"model\",\"name\"]]],null],\"user-plus\",\"btn-default\",\"group_invitation.invite_user\",[28,[37,2],[[30,0,[\"model\",\"custom_fields\",\"allow_invite_users\"]]],null]]]]],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/GroupInvitation/discourse/templates/connectors/group-details-after/invite.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/GroupInvitation/discourse/templates/connectors/group-details-after/invite.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"d-button\",\"action\",\"not\"]]",
    "moduleName": "discourse/plugins/GroupInvitation/discourse/templates/connectors/group-details-after/invite.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});